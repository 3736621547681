.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.container {
  display: flex;
  flex-direction: column;
}

.headingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.centeredContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.centeredLeftJustifyContent {
  align-items: center;
  justify-content: left;
  margin-left: 10vw;
}

.fui-Subtitle2 {
  margin-bottom: 0.5em;
  margin-top: 1em;
}

.fui-Title1 {
  margin-bottom: 0.5em;
}

.optionalChoicesContainer {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  padding-bottom: 0.5em;
  border: 2px solid silver;
  border-radius: 4px;
  /* max-width: -moz-fit-content;
  max-width: fit-content; */
  width: 40vw;
}

.optionalField {
  width: 100%;
}

.requiredField {
  width: 40vw;
}

.infoDisplayContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.methodHeader {
  justify-content: left;
  font-family: Consolas, "courier new";
  font-weight: bold;
  position: relative;
}

.ContentContainer,
.LinkContainer {
  display: grid;
}

.LeftContentContainer,
.LeftLinkContainer {
  grid-column: 1;
  grid-row: 1;
  margin-right: 5vw;
}

.RightContentContainer,
.RightLinkContainer {
  grid-column: 2;
  grid-row: 1;
  margin-right: 10vw;
}

.Divider {
  margin-top: 2em;
  margin-bottom: 2em;
}

.StartAgainButtonContainer {
  align-items: center;
  justify-content: right;

  margin-right: 10vw;
}

.AdditionalActionButton {
  margin-right: 1em !important;
}

li::marker {
  content: "👉";
}

li:hover::marker {
  content: "🤪";
}

.footer {
  margin-left: 2em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tomContainer {
  display: inline-grid;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  margin-top: 3em;
  text-align: left;
}

.Alert {
  margin-top: 1em;
}
